<template>
  <div>
    <div class="groundimg" >
       <!-- <img src=""/>-->
    </div>
    <div>
       <van-list
       v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <div   v-for="item in goods"  :key="item.id"
   :name="item.id">
    <div class="main-goodsList">
    <div class="left-goodsList">
      <div><img style="margin-left:1vw;margin-right:1vw;width:46vw;height: 46vw"
      :src="item.cover_image" @click="showDetail(item)"/></div>
      <div> <div >
          <span   class="p1" @click="showDetail(item)">
            <img  :src="item.platform_icon" 
           @click="$router.push(item.detail_web_url)" 
           style="width:15px;height:15px"/>{{item.title}}</span>
      </div></div>
     <div style="height:20px;"> <span class="info-goodsList">{{item.shop_name}}</span></div>
     <div> <span class="word4-goodsList">¥{{item.discount_price}}</span>
     <span  class="info-goodsList" style="text-decoration:line-through">¥{{item.price}}</span>
     <span class="info-goodsList" style="  margin: 6px 0 0 7px;">已售{{item.month_sales}}</span></div>
     <div style="height:30px;"><span class="span1-goodsList">  
       <span class="word10-goodsList">券</span>
       <span class="word10-goodsList">¥{{item.coupon_money}}</span>    </span>
       <span  style=" font-size: 10px; color: rgba(255, 0, 0, 1);background-color: rgba(254, 240, 240, 1);
         margin-left:20px;">分享赚¥{{item.makeup}}</span></div>
    </div>
    </div>
  </div>
  </van-list>
  </div>
  </div>
</template>
<script>
import {geGuesstObj} from '@/api/goods/goods'
export default {
   props: {
    deviceType:{
      type: String,
      default () {
        return []
      }
    },
    deviceValue:{
      type: String,
      default () {
        return []
      }
    },
       typeValue:{
       type: String,
       default () {
        return '1'
      }
    },
   },
  
   data() {
    return {
          goods: [],
          loading: false,
          finished: false,
          total: 0,//总共的数据条数
          page: {
          currentPage: 1, // 当前页数
          pageSize: 20, // 每页显示多少条
        },
    };
 
  },
  mounted()
  {
    this.getLikeGoods()
  },
methods:
{
    getLikeGoods()
    {
     //let params1 ={'deviceType':'IMEI','deviceValue':'1'}
     let params1 ={'deviceType':this.deviceType,'deviceValue':this.deviceValue}
    const object2 = Object.assign({page: this.page.currentPage, pageSize: this.page.pageSize}, params1);
     geGuesstObj(object2).then((re) => {
         let rows = re.data.data.data.data; //请求返回当页的列表
    // 将新数据与老数据进行合并
         if(re.data.code===0)
         {
            this.goods = this.goods.concat(rows);
         }
     //如果列表数据条数>=总条数，不再触发滚动加载
        this.loading = false; 
        //alert(JSON.stringify(this.total))
       
        if (rows == null || rows.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        if (this.goods.length>=200) {
            this.finished = true;
        }
  //this.goods=res.data.data.data.data
  //alert(JSON.stringify(this.goods))
                }).catch(err=>{
                 console.log(err)   
                    }
                )
    },

     showDetail(item)
   {
     let params={'Id':item.item_id,'platformId':item.platform_id,
      'deviceType':this.deviceType,'deviceValue':this.deviceValue,'type':'0'}
    if(this.$store.state.session!=null&&this.$store.state.session!='')
    {
    let params1={'session':this.$store.state.session}
    params=Object.assign(params,params1)
    }
    if(this.$store.state.token!=null&&this.$store.state.token!='')
    {
     let params1={'token':this.$store.state.token}
     params=Object.assign(params,params1)
    }
    if(this.$store.state.empower!=null&&this.$store.state.empower!='')
    {
     let params1={'empower':this.$store.state.empower}
     params=Object.assign(params,params1)
    }
     this.$router.push({name:'Goodsdetails',query:params})
     if(this.typeValue=='1')
     {
       location.reload()
     }

    }, 
     onLoad() {
      this.page.currentPage++;
      this.getLikeGoods();
    },
},
}
</script>

<style lang="less" scoped>
.groundimg
{
   background: url(/img/youlike.png)
    100% no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 96% 96%;
    height:30px;
    margin-left:2%;
    margin-right:2%
}
.left-goodsList,.right-goodsList{
  float:left; 
  margin-top: 10px;
  margin-left: 1%;
   margin-right: 1%;
   width:48%; height:61px;  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
  }
.right-goodsList{ float:right; }
.info-goodsList {
  overflow-wrap: break-word;
  color: rgb(204, 197, 197);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 15px;
  text-align: left;
  margin-left: 5px
}
.word4-goodsList {
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 1px;
}

.word10-goodsList {
  line-height: 10px;
  margin-left: 5px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
}
.p1{
//text-align: center;
//height:34px;
font-size:17px;margin-left:5px;
text-overflow: -o-ellipsis-lastline;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
line-height: 20px
}
.span1-goodsList
{
  margin-left: 5px;
  background: url(/img/youhui-1.png)
    100% no-repeat;
  width:46px;

  //	width: 46px;
  //height:18px;
	/* 
	使用% 设置宽高以适应父元素的大小
	因为可能得高度坍塌，使用padding-top=（原图高/原图宽）设置高度
	*/
	//background-size: cover;
	//background-position: center;
	/*
	使用 background-size: cover; 自适应铺满
	background-position: center; 兼容不支持上面语句的浏览器版本
	*/
	display: inline-block;
}
</style>
