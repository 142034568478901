c<template>
  <div>

    <div >
      <van-nav-bar 
        title="签到"
        left-arrow
        :fixed="true"
        @click-left="Closeback"
      >
        <!-- <div slot="right">  style="    background: -webkit-linear-gradient(left, #ff7200, #ff6100);"
          <span>签到规则 </span>
        </div> -->
      </van-nav-bar>
    </div>
    <!--签到主体-->
    <div style="overflow: scroll">
      <!---->
      <div class="sigin-div">
        <!-- 签到 -->
        <div   @click="onSingin()">
          <div class="radis" >{{singinStatu}}</div>
        </div>
      </div>
      <!-- 签到日期 -->
      <div class="daycss">
        <div><span style="margin-left:10px;padding-top:10px">已经连续签到{{singinInfo.continuDays}}天</span></div>

        <div style="margin-top:20px;margin-left:10%;">
          <!--连续签到天数-->
       <div v-if="singinInfo.continuDays>0">
         <van-col v-for="i in singinInfo.continuDays"  :key="i" span="3">
           <div :class="(i==singinInfo.continuDays) && singinStatu=='已签到'?'divWeek-singin':'divWeek'"  >
             <span class="singinWord">+{{bonuspoint[i-1].posts}}</span></div>
           <div><span>第{{i}}天</span></div>
         </van-col>
         <!-- <van-col  v-for="m in 7-this.singinInfo.continuDays"  :key="m">
           <div  :class="m==this.singinInfo.continuDays&&this.singinStatu=='已签到'?'divWeek-singin': 'divWeek'" @click="onSingin(nowWeek)" ><span class="singinWord">+1</span></div>
           <div><span>第{{m+(this.singinInfo.continuDays)}}天</span></div>
         </van-col>-->
            <van-col  v-for="m in 7-singinInfo.continuDays"  :key="m+singinInfo.continuDays" span="3">
           <div class='divWeek-singinOut'  @click="onSingin()" ><span class="singinWord">+{{bonuspoint[(m+singinInfo.continuDays-1)].posts}}</span></div>
            <div><span>第{{m+singinInfo.continuDays}}天</span></div>
         </van-col>
         </div>
          <!--连续签到天数为0-->
         <div v-else-if="singinInfo.continuDays<=0">
            <van-col  v-for="n in 7"  :key="n" span="3">
           <div   class="divWeek-singinOut" @click="onSingin()" ><span class="singinWord">+{{bonuspoint[n-1].posts}}</span></div>
           <div><span>第{{n}}天</span></div>
         </van-col>
         </div>
        </div>
      </div>
      <!--猜你喜欢-->
      <div style="margin-top:10px"> <likeList  :deviceType="deviceType1"  :deviceValue="deviceValue1"   :typeValue="typeValue"/></div>

    </div>
  </div>
</template>
<script>
import likeList from '@/views/mylike/index.vue'
import {setObj,getObj} from '@/api/singin/singin'
import { Toast } from 'vant';

export default {
 components: { 
    likeList
    },
  data() {
    return {
     singinStatu:'未签到',
     singinInfo:{},
     nowWeek: '',
     nowDate: '',
     continuDays:'',
     deviceType1:'IMEI',
     deviceValue1:'1',
     classStatus:'singinWord',
     session:'',
     bonuspoint:[],
     typeValue:'0',
    };
  },
  created() {},
  mounted()
  {
      if(this.$route.query.deviceValue!=null)
      {
       this.deviceValue1=this.$route.query.deviceValue
      }
      if(this.$route.query.deviceType!=null)
      {
       this.deviceType1=this.$route.query.deviceType
      }
      if(this.$route.query.session!=null)
      {
        this.session=this.$route.query.session
        this.$store.commit('setSession', this.session)
      }
       //获取app传递过来的token
   if(this.$route.query.token!=null&&this.$route.query.token!='')
  {
    this.$store.commit('setToken', this.$route.query.token)
  }
    //empower
   if(this.$route.query.empower!=null&&this.$route.query.empower!='')
  {
    this.$store.commit('setEmpower', this.$route.query.empower)
  }
      //alert(this.$route.query.session)
    this.getObj()

    //this.setNowTimes()
  },
  methods: {
   //获取签到信息
   getObj()
   {
       let today = new Date();
       getObj().then((res)=>
       {
       
         if(res.data.code==0)
         {
           
           this.singinInfo=res.data.data;
           this.bonuspoint=res.data.data.signConfigList
           this.singinInfo=this.singinInfo.signRecord
           //alert(JSON.stringify(this.singinInfo))
            if(this.singinInfo.createTime==null)
           {
                this.singinStatu='未签到'
                return;
           }
            //var timestamp2 = Date.parse(new Date(this.singinInfo.updateTime));
            let strDate=this.singinInfo.updateTime!=null?this.singinInfo.updateTime:this.singinInfo.createTime
            let dateinfo =this.stringToDate(strDate)// this.stringToDate(strDate)//new Date(this.singinInfo.updateTime!=null?this.singinInfo.updateTime:this.singinInfo.createTime); 
            //Toast(this.stringToDate(strDate).getFullYear())
             //new Date(Date.parse(new Date(strDate)));
            //Toast(dateinfo.getFullYear()+","+today.getFullYear())
           if(dateinfo.getFullYear()!=today.getFullYear()||dateinfo.getMonth()!=today.getMonth())
           {
             this.singinStatu='未签到'
             return;
           }
          if(dateinfo.getFullYear()==today.getFullYear()&&dateinfo.getMonth()==today.getMonth())
           {  
             if(dateinfo.getDate()!=today.getDate())
             {
              
               this.singinStatu='未签到'
             }else{
               this.singinStatu='已签到'
             }
           }
         } else{
           this.$toast('服务器繁忙')
         }
       }
       )
   },
   //签到
   setObj()
   {
       setObj().then((res)=>
       {
         if(res.data.code==0)
         {
           this.singinStatu='已签到'
           this.getObj()
           Toast('签到成功')
         }
        else{
           this.$toast('服务器繁忙')
         }
       })
   },
  stringToDate(str) {
  let tempStrs = str.split(" ");
        let dateStrs = tempStrs[0].split("-");
        let year = parseInt(dateStrs[0], 10);
        let month = parseInt(dateStrs[1], 10) - 1;
        let day = parseInt(dateStrs[2], 10);
        let timeStrs = tempStrs[1].split(":");
        let hour = parseInt(timeStrs [0], 10);
        let minute = parseInt(timeStrs[1], 10);
        let second = parseInt(timeStrs[2], 10);
        let date = new Date(year, month, day, hour, minute, second);
        return date;

      

      /*var d = new Date();
d.setYear(parseInt(s.substring(0,4),10));
d.setMonth(parseInt(s.substring(5,7)-1,10));
d.setDate(parseInt(s.substring(8,10),10));
d.setHours(parseInt(s.substring(11,13),10));
d.setMinutes(parseInt(s.substring(14,16),10));
d.setSeconds(parseInt(s.substring(17,19),10));
return d;*/

      },
 formDate (time) {
      const thisTime = time.replace(/ /g, '/')
      var nowTime = new Date(thisTime)
      nowTime = nowTime.getTime()
      return nowTime
    },
  setNowTimes () {
  let myDate = new Date()
  // console.log(myDate)
  let wk = myDate.getDay()
  let yy = String(myDate.getFullYear())
  let mm = myDate.getMonth() + 1
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
  let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
  let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
  let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  let week = weeks[wk]
  this.nowDate = yy + '-' + mm + '-' + dd
  this.nowTime = hou + ':' + min + ':' + sec
  this.nowWeek = week
},
//签到操作
onSingin()
{
  if(this.singinStatu!='已签到')
  {
   this.setObj()
   console.log('未签到')
  }
},
Closeback()
    {
     let u = navigator.userAgent
     let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
     let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
     if(isIOS)
     {
          window.webkit.messageHandlers.close.postMessage('up');   
     }
     if (isAndroid) {
        window.android.close(); 
      } 
     },
  },
};
</script>
<style  scoped>
.sigin-div {
  background: url(/img/sigin.png) 100%  no-repeat;
  height: 300px;
    background-size: 100%;
  justify-content: flex-end;
  /*position: fixed;*/
  width: 100%;
}
.like {
  background: url(/img/编组6.png) 100% repeat;
  /*position: fixed;*/
  width: 100%;
  height: 30px;
  margin-top: 20px;
  /* margin-top: 360px;*/
}
.goods-like {
  /*position: fixed;*/
  width: 100%;
  position: absolute;
  padding-bottom: 20px;
  /*margin-top: 400px;*/
}
.radis {
  /*margin-top: 100px;*/
  margin-top: 100px;
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  line-height: 100px;
  text-align: center;
  margin-left:calc(50% - 50px);
  font-size: 20px;
  font-weight: bold;
  background-color: #fbe9c2;
}
.daycss {
  /*position:fixed;*/
  /* margin-top:270px;*/
  margin-top:-40px;
  margin-left: 3%;
  margin-right: 3%;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  width: 94%;
  height: 100px;
}
.left,
.right {
  float: left;
  margin-top: 10px;
  margin-left: 1%;
  margin-left: 1%;
  width: 40%;
  height: 61px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
}
.right {
  float: right;
}
.word {
  left: 11px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
}
.info {
  overflow-wrap: break-word;
  color: rgb(204, 197, 197);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
}
.word4 {
  overflow-wrap: break-word;
  color: rgb(243, 132, 132);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 1px;
}
.word10 {
  margin-left: 5px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: center;
}
.divWeek
{
  border-radius: 50%;
	text-align: center;
  background-color: #fbe9c2;
  width:30px;
	height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
}
.divWeek-singinOut
{
  border-radius: 50%;
	text-align: center;
  background-color: #eeebe6;
  width:30px;
	height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
}
.divWeek-singin
{
  border-radius: 50%;
	text-align: center;
  background-color: #ff6f00;
  width:30px;
	height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
}
.singinWord
{
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  text-align:center;
  margin-left:7px;
}
.col
{
  width:14%
}

</style>
